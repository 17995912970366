@import 'common/styles/layout';

.root {
  height: 200px;
  width: 200px;
  position: relative;
  box-shadow: $box-shadow-normal;
}

.backgroundImage {
  height: 200px;
  width: 200px;
}

.icon {
  position: absolute;
  left: 75px;
  bottom: 75px;
  background: gray;
  opacity: 0.9;
  border-radius: $border-radius-circle;
  padding: $spacing-sm;
  display: flex;
}

.hidden {
  display: none;
}

.clickable {
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}

.loading {
  opacity: 0.5;
  cursor: pointer;
}
