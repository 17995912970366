.root {
  text-align: center;
}

.acknowledgeArea {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkboxArea {
  display: flex;
  align-items: center;
}

.checkboxText {
  margin-left: 10px;
}

.acceptButton {
  margin: 10px;
  width: 200px;
}
