@import 'common/styles/layout';
@import 'common/styles/typography';

.email {
  font-size: $font-size-sm;
  font-weight: $font-weight-md;
  letter-spacing: $letter-spacing-sm;
  text-transform: uppercase;
}

.logoutButton {
  width: 100%;
}

.navLink {
  margin-bottom: $spacing-md;
}
