@import 'common/styles/animation';
@import 'common/styles/color';
@import 'common/styles/layout';
@import 'common/styles/typography';

.root {
  padding: $spacing-md;
  background: $background-color;
  box-shadow: $box-shadow-normal;
  border-radius: $border-radius-normal;
  transition: transform $transition-normal;
  &:hover {
    transform: scale(1.02);
  }
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.clientId {
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
