@import 'common/styles/color';
@import 'common/styles/layout';

.root {
  height: 100vh;
  width: 100%;
  background: $background-color;
}

.content {
  padding: $spacing-lg;
  margin: auto;
  max-width: $max-page-width;
}
