@import 'common/styles/layout';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions {
  display: flex;
}

.moreOptionsIcon {
  margin-bottom: $spacing-sm;
}
