@import 'common/styles/layout';

.placeholder {
  height: $height-avatar;
  width: $height-avatar;
}

.avatar {
  border-radius: $border-radius-circle;
  height: $height-avatar;
  width: $height-avatar;
}
