@import 'common/styles/animation';
@import 'common/styles/color';
@import 'common/styles/layout';
@import 'common/styles/typography';

.root {
  transition: $transition-normal;

  &:hover {
    transform: scale(1.05);
  }
}

.navLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: $font-size-sm;
  letter-spacing: $letter-spacing-sm;
  font-weight: $font-weight-md;
  width: 100%;

  padding: 0px 0px $spacing-sm 0px;
  margin: 0px 0px $spacing-sm 0px;

  @media (min-width: $breakpoint-sm) {
    margin: 0px $spacing-md 0px 0px;
    padding: 0px;
    width: min-content;
  }
}

.currentLocationUnderline {
  padding-bottom: $spacing-xxs;
  border-bottom: $spacing-xxs solid $teal;
}

.rightArrow {
  height: 15px;
  width: 15px;
}
