@import 'common/styles/layout';

.grid {
  display: flex;
  flex-wrap: wrap;
}
.itemWrapper {
  @media (min-width: 300px) {
    width: 100%;
  }
  @media (min-width: 600px) {
    width: 50%;
  }
  @media (min-width: 800px) {
    width: 33%;
  }
}

.item {
  max-width: 100%;
  margin: 10px;
}
