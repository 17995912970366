@import 'common/styles/color';
@import 'common/styles/layout';

.root {
  padding: $spacing-md 0;
  display: flex;
  align-items: center;
}

.avatar {
  margin-right: $spacing-sm;
}

.name {
  font-weight: 600;
  margin-top: 0;
  margin-bottom: $spacing-xs;
  padding: 0;
  color: $text-secondary;
}

.email {
  margin: 0;
  padding: 0;
}
