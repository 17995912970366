$black: var(--whoop-color-black);
$error: var(--whoop-color-red);
$white: var(--whoop-color-white);
$red: var(--whoop-color-red);
$branding-red: var(--whoop-color-branding-red);
$teal: var(--whoop-color-teal);

$background-color: #101518;
$contrast-color: white;

$text-secondary: #969696;
