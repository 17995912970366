@import 'common/styles/color';
@import 'common/styles/layout';

.root {
  height: $height-header;
  box-shadow: $box-shadow-normal;
}

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: $max-page-width;
  margin: auto;
  padding: 0 $spacing-lg;
}

.logo {
  height: $height-header-logo;
  width: $height-header-logo;
  color: $contrast-color;
}

.rightCorner {
  display: flex;
  align-items: center;
}

.desktopNavOptions {
  margin: $spacing-sm 0;
  display: flex;
}
