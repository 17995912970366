@import 'common/styles/layout';

.root {
  padding: $spacing-md 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
