@import 'common/styles/color';
@import 'common/styles/layout';

.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $background-color;
}

.logoWrapper {
  display: flex;
  flex-direction: column;
}

.logo {
  width: 16rem;
  height: 3rem;
  margin: auto;
  margin-bottom: $spacing-lg;
}

.card {
  border-radius: $border-radius-normal;
  padding: $spacing-lg;
  margin: auto;
  box-shadow: $box-shadow-normal;
  max-width: 500px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
