@import 'common/styles/layout';
@import 'common/styles/typography';

.root {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-md;
}

.scopeText {
  padding-left: $spacing-sm;
  width: 100%;
}

.scopePre {
  margin: 0;
  margin-bottom: $spacing-xs;
}

.scopeDescription {
  margin: 0;
  padding: 0;
  font-size: $font-size-sm;
}
