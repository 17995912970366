@import 'common/styles/color';
@import 'common/styles/layout';
@import 'common/styles/typography';

.formContainer {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.formElement {
  margin-top: $spacing-sm;
}

.signInText {
  letter-spacing: $letter-spacing-lg;
  text-transform: uppercase;
}

.signInButton {
  width: 100%;
  margin-top: $spacing-sm;
}

.forgotPasswordLink {
  text-align: center;
  text-decoration: underline;
  margin: $spacing-sm;
  cursor: pointer;
}
