@import 'common/styles/color';
@import 'common/styles/layout';
@import 'common/styles/typography';

.root {
  padding: $spacing-sm 0;
}

.label {
  letter-spacing: $letter-spacing-sm;
  font-weight: $font-weight-lg;
  font-size: $font-size-sm;
  text-transform: uppercase;
  margin: $spacing-sm 0;
  color: $text-secondary;
}

.body {
  margin: 0;
  padding: 0;
}
