@import 'common/styles/color';
@import 'common/styles/layout';

.root {
  display: flex;
  align-items: center;
}

.revealButton {
  margin-left: $spacing-sm;
  color: $contrast-color;
}

.pre {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}
