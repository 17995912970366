$spacing-xxs: 2px;
$spacing-xs: 5px;
$spacing-sm: 10px;
$spacing-md: 20px;
$spacing-lg: 30px;
$spacing-xl: 40px;

$border-radius-normal: 5px;

// $box-shadow-normal: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
$box-shadow-normal: 0px 0px 1px 1px rgba(255, 255, 255, 0.3);

$max-page-width: 1000px;

$border-radius-circle: 50%;

$height-header: 70px;
$height-header-logo: 32px;
$height-avatar: 32px;

$breakpoint-xs: 300px;
$breakpoint-sm: 720px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1200px;

$z-header-menu: 999;
