@import 'common/styles/animation';
@import 'common/styles/color';
@import 'common/styles/layout';
@import 'common/styles/typography';

.root {
  position: relative;
}

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  right: 0;
  z-index: $z-header-menu;
  margin-top: $spacing-xs;
  padding: $spacing-md;
  background: $background-color;
  box-shadow: $box-shadow-normal;
  border-radius: $border-radius-normal;
  font-size: $font-size-sm;
}
